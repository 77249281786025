.App {
  text-align: center;
}

.logo {
  height: auto;
  padding: 10px;
}

.site-layout {
  background: rgba(196, 196, 196, 0.3);
}

.ql-container {
  min-height: 200px;
}